import { NgIf, CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, DestroyRef, Inject, effect, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { LocalPermissions, GlobalPermissions } from 'src/models/global-enum';
import { PermissionsService } from 'src/services/permissions.service';
import { RegexMatchPipe } from '../../pipe/regex.pipe';
import { TimeToHoursPipe } from '../../pipe/timetoHours.pipe';
import { TimeToMeridianPipe } from '../../pipe/timeToMeridian.pipe';
import { UserService } from '@root/services/user-service/user.service';
import { GeneralStore } from '@root/store/general.store';
import { UserInfo } from '@models/user.model';
import { AssignedLocation, LocationsStore } from '@root/store/location.store';
import { HeaderComponent } from '../header/header.component';
import {
  Menu,
  Earth,
  Users,
  MapPinPlus,
  LucideAngularModule,
  ChevronRight,
  ChartBarDecreasing,
  LayoutDashboard,
  Book,
  Map,
} from 'lucide-angular';
import { LucideIconData } from 'lucide-angular/icons/types';
import { NewActionButtonComponent } from '../new-action-button/new-action-button.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    CommonModule,
    MatMenuModule,
    RouterModule,
    MatExpansionModule,
    RegexMatchPipe,
    TimeToHoursPipe,
    TimeToMeridianPipe,
    HeaderComponent,
    NgOptimizedImage,
    LucideAngularModule,
    NewActionButtonComponent
  ],
})
export class SidebarComponent {
  readonly Menu: LucideIconData = Menu;
  readonly Earth: LucideIconData = Earth;
  readonly Map: LucideIconData = Map;
  readonly ChartBarDecreasing: LucideIconData = ChartBarDecreasing;
  readonly LayoutDashboard: LucideIconData = LayoutDashboard;
  readonly Book: LucideIconData = Book;
  readonly Users: LucideIconData = Users;
  readonly MapPinPlus: LucideIconData = MapPinPlus;
  readonly ChevronRight: LucideIconData = ChevronRight;
  currentUser?: UserInfo;
  showSubmenu = false;
  isShowing = false;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  submenuState: any = {
    userAndRole: false,
    locations: false,
    selectedLocation: false,
  };
  path = '';
  /* eslint-disable @typescript-eslint/no-explicit-any */
  locations: any[] = [];

  /* eslint-disable @typescript-eslint/no-explicit-any */
  isExpanded = true;
  selectedLocation: any;
  locationSubmenuItems: any;
  readonly generalStore = inject(GeneralStore);
  readonly locationStore = inject(LocationsStore);
  public isGlobalAdmin = false;
  public isGlobalViewer = false;
  public isReportAdmin = false;
  public isReportViewer = false;

  constructor(
    private router: Router,
    public permissionsService: PermissionsService,
    public userService: UserService,
    @Inject(DestroyRef) public destroyRef: DestroyRef,
  ) {
    effect(() => {
      this.isExpanded = this.generalStore.sidebarExpanded();
      if (!this.isExpanded) {
        this.submenuState = {
          userAndRole: false,
          locations: false,
          selectedLocation: false,
        };
      } else {
        this.activeCall(this.router.url);
      }
    });

    effect(() => {
      const tempUser: UserInfo | null = this.generalStore.userInfo();
      if (tempUser) {
        this.currentUser = tempUser;
      }
    });

    effect(() => {
      const globalCreds: GlobalPermissions[] | null = this.generalStore.globalCreds();
      if (globalCreds) {
        this.isGlobalAdmin = globalCreds.indexOf(GlobalPermissions.GLOBAL_ADMIN) > -1;
        this.isGlobalViewer = globalCreds.indexOf(GlobalPermissions.GLOBAL_VIEWER) > -1;
        this.isReportAdmin = globalCreds.indexOf(GlobalPermissions.REPORT_ADMIN) > -1;
        this.isReportViewer = globalCreds.indexOf(GlobalPermissions.REPORT_VIEWER) > -1;
      }
    });

    effect(() => {
      const tempLocations: AssignedLocation[] | null = this.generalStore.assignedLocations();
      if (tempLocations && tempLocations.length > 0) {
        this.locations = tempLocations;
        this.onRefreshActiveTab(this.router.url);
      }
    });

    this.router.events.subscribe(() => {

       if (this.path != this.router.url && (!this.router.url.includes('/tasks/') && !this.router.url.includes('/task-sets/') || this.router.url.includes('/tasks/closed'))) {
      this.path = this.router.url;
      this.activeCall(this.path);
      }
    });
  }

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
    this.generalStore.setSidebarExpanded(this.isExpanded);
  }

  expandSidebar() {
    this.isExpanded = true;
    this.generalStore.setSidebarExpanded(true);
  }

  onRefreshActiveTab(path: string) {
    this.path = path;
    if (path === '/global/users-and-roles/users' || path === '/global/users-and-roles/roles') {
      this.expandSubmenu('userAndRole');
    } else if (path.indexOf('/locations') > -1 && path.indexOf('/locations-and-categories') < 0) {
      this.expandSubmenu('locations');
      this.openToLocation(path);
    } else if (path.indexOf('/locations') > -1 && path.indexOf('/local-dashboard') > -1) {
      this.expandSubmenu('locations');
      this.openToLocation(path);
    }
  }

  getLocalViewerPermission(locationId: number) {
    return this.permissionsService.checkViewOnly(locationId);
  }

  openToLocation(path: string) {
    if (path.indexOf('/locations/') < 0) {
      /* eslint-disable @typescript-eslint/typedef */
      this.selectedLocation = null;
    } else {
      this.expandSubmenu('locations');
      const values = path.split('/');
      // pull the locationID out of the route
      const tempLocation = this.locations.find(location => {
        return location.locationID === Number(values[2]);
      });
      this.selectLocation(tempLocation, path);
    }
  }

  navClick(path: string) {
    if (
      (this.submenuState.userAndRole && path === 'global/users-and-roles') ||
      (this.submenuState.locations && path === '/locations')
    ) {
      this.expandSubmenu('none');
    } else {
      if (!this.isExpanded) {
        this.expandSidebar();
        Promise.resolve().then(() => {
          this.activeCall(path);
        });
      } else {
        this.activeCall(path);
      }
    }
  }

  activeCall(path: string) {
    if (path.indexOf('global/users-and-roles') >= 0) {
      this.expandSubmenu('userAndRole');
    } else if (path.indexOf('locations') >= 0 && path.indexOf('locations-and-categories') < 0) {
      this.expandSubmenu('locations');
      this.openToLocation(path);
    } else if (
      (this.submenuState.userAndRole || this.submenuState.locations) &&
      ((path.indexOf('global/users-and-roles/users') < 0 &&
        path.indexOf('global/users-and-roles/roles') < 0) || 
        path.indexOf('locations-and-categories') >= 0)
    ) {
      // Collapse all submenus if a row without a submenu is clicked
      this.expandSubmenu('none');
    }
  }

  expandSubmenu(key: string) {
    if (!key) return;
    Object.keys(this.submenuState).forEach((k: string) => {
      if (key === k) {
        this.submenuState[k] = true;
      } else {
        this.submenuState[k] = false;
      }
    });
  }

  selectLocation(location: any, path: string) {
    if (location && location.locationID === this.selectedLocation?.locationID) {
      this.submenuState.selectedLocation = !this.submenuState.selectedLocation;
      if (this.submenuState.selectedLocation && path.split('/').length < 4) {
        this.selectedLocation = null;
        this.submenuState.selectedLocation = false;
        this.submenuState.locations = true;
      } else {
        this.selectedLocation = location;
        this.setLocationSubmenu(this.selectedLocation.locationID);
        this.submenuState.selectedLocation = true;
        this.submenuState.locations = true;
      }
    } else if (!location) {
      this.selectedLocation = null;
      this.submenuState.selectedLocation = false;
      this.submenuState.locations = true;
    } else {
      this.selectedLocation = location;
      /* eslint-disable @typescript-eslint/typedef */
      this.setLocationSubmenu(this.selectedLocation.locationID);
      this.submenuState.selectedLocation = true;
      this.submenuState.locations = true;
    }
  }

  setLocationSubmenu(locationId: number) {
    const routerLinkPrefix = '/locations/' + locationId + '/';
    this.locationSubmenuItems = [
      {
        id: 'localDashboard',
        displayText: 'Local Dashboard',
        iconName: 'heroMapPinSolid',
        routerLink: routerLinkPrefix + 'local-dashboard',
        hasPermission: this.permissionsService.checkRoutePermissions([], locationId, []),
      },
      {
        id: 'myTasks',
        displayText: 'My Tasks & Task Sets',
        iconName: 'heroQueueListSolid',
        routerLink: routerLinkPrefix + 'my-tasks',
        hasPermission: this.permissionsService.checkRoutePermissions([], locationId, []),
      },
      {
        id: 'completedTasks',
        displayText: 'Closed Tasks',
        iconName: 'heroCheckCircleSolid',
        routerLink: routerLinkPrefix + 'tasks/closed',
        hasPermission: this.permissionsService.checkRoutePermissions([], locationId, [
          LocalPermissions.LOCAL_ADMIN,
          LocalPermissions.LOCAL_VIEWER,
          LocalPermissions.ALLOW_TASK_SCORING,
          LocalPermissions.REASSIGN_MY_TASKS_AND_TASK_SETS_TO_OTHERS,
          LocalPermissions.COMPLETE_OTHERS_TASKS,
          LocalPermissions.DELETE_SPAWNED_TASKS,
          LocalPermissions.SPAWN_FROM_EVENT_WIZARD,
          LocalPermissions.ASSIGN_TASK_AND_SETS_TO_OTHERS,
        ]),
      },
      {
        id: 'eventWizard',
        displayText: 'Event Wizard',
        iconName: 'heroExclamationCircleSolid',
        routerLink: routerLinkPrefix + 'event-wizard',
        hasPermission: this.permissionsService.checkRoutePermissions([], locationId, [
          LocalPermissions.LOCAL_ADMIN,
          LocalPermissions.LOCAL_VIEWER,
          LocalPermissions.SPAWN_FROM_EVENT_WIZARD
        ]),
      },
      {
        id: 'taskTemplates',
        displayText: 'Task Templates',
        iconName: 'heroRectangleGroupSolid',
        routerLink: routerLinkPrefix + 'templates',
        hasPermission: this.permissionsService.checkRoutePermissions([], locationId, [
          LocalPermissions.LOCAL_ADMIN,
          LocalPermissions.LOCAL_VIEWER,
        ]),
      },
      {
        id: 'users',
        displayText: 'Users',
        iconName: 'heroUserSolid',
        routerLink: routerLinkPrefix + 'users',
        hasPermission: this.permissionsService.checkRoutePermissions([], locationId, [
          LocalPermissions.LOCAL_ADMIN,
          LocalPermissions.LOCAL_VIEWER,
        ]),
      },
      {
        id: 'roles',
        displayText: 'Roles',
        iconName: 'heroUsersSolid',
        routerLink: routerLinkPrefix + 'roles',
        hasPermission: this.permissionsService.checkRoutePermissions([], locationId, [
          LocalPermissions.LOCAL_ADMIN,
          LocalPermissions.LOCAL_VIEWER,
        ]),
      },
      {
        id: 'documents',
        displayText: 'Documents',
        iconName: 'heroDocumentSolid',
        routerLink: routerLinkPrefix + 'documents',
        hasPermission: this.permissionsService.checkRoutePermissions([], locationId, [
          LocalPermissions.LOCAL_ADMIN,
          LocalPermissions.LOCAL_VIEWER,
        ]),
      },
    ];
  }
}
