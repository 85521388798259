import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface LoaderData {
  loadingText: string;
  spinnerDiameter: number;
}

@Component({
  selector: 'app-loading-dialog',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: './loading-dialog.component.html',
  styleUrl: './loading-dialog.component.scss'
})
export class LoadingModalDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LoadingModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoaderData) {}

}
